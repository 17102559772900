import React, { useState, useEffect } from "react"
import { Alert } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faEye, faEyeSlash, faCheck } from '@fortawesome/free-solid-svg-icons'
import { fetchTags } from '../../requests/Tags'
import { ObjectListing } from '../../components/ObjectListing'
import { Time } from '../../components/Time'
import FlashMessage from '../../components/FlashMessage'
import { fetchCategories } from "../../requests/Categories"

export function AdminProducts() {
    const [tags, setTags] = useState([])
    const [categories, setCategories] = useState([])
    const [error, setError] = useState(null)
    useEffect(() => {
        const abortController = new AbortController();
        fetchCategories(setCategories, setError, function () {
            fetchTags(abortController, setTags, setError);
        })
        return () => {
            abortController.abort();
        }
    }, [])

    var filters = [{
        label: 'nur versteckte',
        boolean: true,
        default: false,
        requestAttribute: 'only_disabled',
    }, {
        label: 'nur sichtbare',
        boolean: true,
        default: false,
        requestAttribute: 'only_visible',
    }]
    if (categories.length > 0) {
        filters.push({
            label: 'Kategorie',
            requestAttribute: 'category_id',
            toOption: (u) => {
                return u.name
            },
            options: categories
        })
    }
    if (tags.length > 0) {
        filters.push({
            label: 'Tag',
            requestAttribute: 'tag_id',
            toOption: (u) => {
                return u.name
            },
            options: tags
        })
    }
    const attributes = [
        {
            key: "serial",
            label: "Interne Nr.",
            order: true,
        },
        {
            key: "name",
            label: "Name",
            order: true,
        },
        {
            key: "modified",
            label: "letzte Änderung",
            order: true,
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            }
        },
        {
            key: "tags",
            label: "Tags",
            order: false,
            format: (tags) => {
                if (Array.isArray(tags) && tags.length > 0) {
                    tags.sort((a, b) => {
                        return a.name.localeCompare(b.name)
                    })
                    return <span>
                        {tags.map((t, i) => {
                            return (i > 0 ? ', ' : '') + t.name
                        })}
                    </span>
                }
                return 'keine'
            }
        },
        {
            key: "name",
            parent: 'category',
            label: "Kategorie",
            order: true,
        },
        {
            key: "disabled",
            label: "Online",
            order: true,
            format: (d) => {
                return d ? <FontAwesomeIcon className="ms-2 text-body-tertiary" icon={faEyeSlash} /> : <FontAwesomeIcon  className="ms-2" icon={faEye} />
            }
        },
        {
            key: "devices",
            label: "aktive Geräte",
            order: false,
            format: (devices, p) => {
                var count = 0;
                devices.forEach((d) => {
                    if (!d.disabled) {
                        count++;
                    }
                })
                return <Link to={"/admin/devices/product/"+p.id}>{count}</Link>;
            }
        },
        {
            key: "plannable",
            label: "im Planner",
            order: true,
            format: (d) => {
                return d ? <FontAwesomeIcon className="ms-2" icon={faCheck} /> : ''
            }
        }
    ];
    if (error) {
        return (
            <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true}>
                <Alert variant="danger">
                    <span>
                        {error.message} {error.detail}
                    </span>
                </Alert>
            </FlashMessage>
        )
    }
    return <ObjectListing objectname="products" showTitle={true} admin={true} title="Produkte" icon={faVideo} attributes={attributes} defaultSort="name" defaultAsc="true" add={true} useuuid={false} edit={true} delete={true} filters={filters} search={true} headerButtons={[]} />
}