export function getFullname(user) {
    return user ? (user.firstname + ' ' + user.lastname) : '...'
}
export function devicesort(a, b) {
    if (a.product.name === b.product.name) {
        return a.name.localeCompare(b.name)
    }
    return a.product.name.localeCompare(b.product.name)
}
export function devlog(msg) {
    if(process.env.REACT_APP_DEV) {
        console.log("DEV "+new Date().toLocaleTimeString()+" "+msg)
    }
}
export function dateToDateTimeLocale(d) {
    if(typeof d === "undefined"){
        return '?'
    }
    if(typeof d === "string"){
        d = new Date(d)
    }
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");
    const hours = d.getHours().toString().padStart(2, "0");
    const minutes = d.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
}
export function primaryItem(token, id, mode, sid, setError) {
    fetch(process.env.REACT_APP_API + '/' + mode + '/primary/' + id + '/' + sid, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
    }).then((response) => {
        if (!response.ok) {
            if (response.status !== 200) {
                setError({ message: 'Das Objekt konnte nicht gespeichert werden' });
                setTimeout(() => {
                    setError(null)
                }, 5000)
            }
        } else {
        }
    })
}
export function deleteItem(token, id, mode, sid, title, setError, cb, setThing) {
    if (window.confirm("'" + title + "' wirklich löschen?")) {
        fetch(process.env.REACT_APP_API + '/' + mode + '/' + sid, {
            method: 'DELETE',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token
            },
        })
            .then(
                (res) => {
                    if (!res.ok) {
                        if (res.status === 409) {
                            setError({ message: 'Das Objekt wird noch verwendet und kann nicht gelöscht werden' });
                            setTimeout(() => {
                                setError(null)
                            }, 5000)
                        }
                    } else {
                        cb(id, token, setThing)
                    }
                },
                (err) => {
                    setError(err);
                }
            )
    }
}
export function getMainImage(product) {
    if(typeof product === 'undefined' || product === null) {
        return false
    }
    var src = process.env.PUBLIC_URL + '/img/missing.webp'
    if (Array.isArray(product.images) && product.images.length > 0) {
        if (product.image_id > 0) {
            product.images.forEach(i => {
                if (i.id === product.image_id) {
                    src = process.env.REACT_APP_DOMAIN + "/ul/products/" + i.id + '.' + i.ext
                }
            });
        } else {
            src = process.env.REACT_APP_DOMAIN + "/ul/products/" + product.images[0].id + '.' + product.images[0].ext
        }
    }
    return src
}
export function checkIsBlocked(device, d) {
    if (device.disabled) {
        return true
    }
    if (!device.timegrid || !device.timegrid.years) {
        return false
    }
    if (!device.timegrid.years[d.getFullYear()]) {
        return false
    }
    if (!device.timegrid.years[d.getFullYear()].months[d.getMonth() + 1]) {
        return false
    }
    if (!device.timegrid.years[d.getFullYear()].months[d.getMonth() + 1].days[d.getDate()]) {
        return false
    }
    return device.timegrid.years[d.getFullYear()].months[d.getMonth() + 1].days[d.getDate()]
}