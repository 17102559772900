import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Navbar, Nav, Container, NavDropdown, Badge, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faUser,
    faCircleHalfStroke,
    faSun,
    faMoon,
    faCartShopping
} from '@fortawesome/free-solid-svg-icons'
import { useAuth } from './Auth'
import { useCart } from './Cart'
import { useConfig } from './Config'

export function Navigation({ setTheme }) {
    const [showCart, setShowCart] = useState(false)
    let auth = useAuth()
    let cart = useCart()
    useEffect(() => {

    }, [cart.total])
    return <Navbar expand="lg" fixed="top">
        <Container>
            <Link className="navbar-brand" to={"/"}>
                <img className="jello-horizontal" src={process.env.PUBLIC_URL + '/img/logo.svg'} alt={process.env.REACT_APP_COMPANY} height="40" />
            </Link>
            <Link className="navbar-brand" to={auth.scopes.includes("mitarbeiter") ? "/admin" : "/"}>
                {process.env.REACT_APP_NAME}
            </Link>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav className="ms-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll>
                    {auth.scopes.includes("mitarbeiter") ? AdminNav() :
                        (auth.scopes.includes("me") ? PrivateNav() : PublicNav())}

                    {auth.scopes.includes("me") ? (
                        <>
                            <Link className="nav-link" to="/me" title="Mein Profil">
                                <FontAwesomeIcon icon={faUser} />
                            </Link>
                            {auth.scopes.includes("nutzer") && (
                                <Link className="nav-link relative" onClick={() => setShowCart(true)} title="Mein Warenkorb" ref={cart.cartRef}>
                                    <FontAwesomeIcon icon={faCartShopping} />
                                    <Badge pill bg="danger" className="cart-pill">
                                        {(!cart.busy && cart.isLoaded) ? (
                                            cart.error?.message?.length > 0 ? (
                                                <span title={cart.error.message}>?</span>
                                            ) : (
                                                (typeof cart.basket !== "undefined" && cart.basket !== null) ?
                                                    Object.keys(cart.basket.items).length : 0
                                            )) : <Spinner />}
                                    </Badge>
                                </Link>
                            )}
                        </>
                    ) : (
                        <Link className="nav-link" to="/login" title="Login">
                            <FontAwesomeIcon icon={faUser} />
                        </Link>
                    )}
                    <NavDropdown title={<FontAwesomeIcon icon={faCircleHalfStroke} title="Theme wählen" />}>
                        <NavDropdown.Item onClick={() => { setTheme('light') }}>
                            <FontAwesomeIcon icon={faSun} className="me-2" /> Hell
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { setTheme('dark') }}>
                            <FontAwesomeIcon icon={faMoon} className="me-2" /> Dunkel
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Container>
        {cart.renderModal(showCart, setShowCart)}
    </Navbar>
}

function PublicNav() {
    let config = useConfig()
    return (
        <>
            <Link className="nav-link" to="/products">
                Produkte
            </Link>
            {config.registration && (
                <Link className="nav-link" to="/register">
                    Registrierung
                </Link>
            )}
        </>
    )
}
function PrivateNav() {
    return (
        <>
            <Link className="nav-link" to="/products">
                Produkte
            </Link>
            <Link className="nav-link" to="/borrowed">
                Vorgänge
            </Link>
        </>
    )
}
function AdminNav() {
    return (
        <>
            <Link className="nav-link" to="/admin/plan">
                Plan
            </Link>
            <Link className="nav-link" to="/admin/rentals">
                Vorgänge
            </Link>
            <Link className="nav-link" to="/admin/products">
                Produkte
            </Link>
            <Link className="nav-link" to="/admin/devices">
                Geräte
            </Link>
            <Link className="nav-link" to="/admin/categories">
                Kategorien
            </Link>
            <Link className="nav-link" to="/admin/tags">
                Tags
            </Link>
            <Link className="nav-link" to="/admin/users">
                Nutzer
            </Link>
            <Link className="nav-link" to="/admin/pages">
                CMS
            </Link>
            <Link className="nav-link" to="/admin/locations">
                Standorte
            </Link>
            <Link className="nav-link" to="/admin/logs">
                Logs
            </Link>
        </>
    )
}