import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

export function ImageCarousel({ images }) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {Array.isArray(images) && images.map((i) => {
                return (
                    <Carousel.Item key={"imagecarousel" + i.id}>
                        <img alt={i.title} className="d-block w-100" src={process.env.REACT_APP_DOMAIN + "ul/products/" + i.id + '.' + i.ext} />
                        <Carousel.Caption>
                            <h3>{i.title}</h3>
                            <p>{i.description}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}