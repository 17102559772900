import { Container } from 'react-bootstrap'
import { LoginForm } from '../components/LoginForm'

export function Login() {
    return (
        <Container id="login" className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="mt-5">Login</h1>
            <div className="w-50 mt-5">
                <LoginForm/>
            </div>
        </Container>
    )
}