import React, { useContext, createContext, useState, useRef, useEffect, useCallback } from "react"
import { useDebouncedCallback } from 'use-debounce'
import { useAuth } from './Auth'
import { CartModal } from './CartModal'

export const cartContext = createContext()
export function ProvideCart({ children }) {
    const auth = useProvideCart();
    return (
        <cartContext.Provider value={auth}>
            {children}
        </cartContext.Provider>
    )
}

export const useCart = () => {
    return useContext(cartContext)
}

function useProvideCart() {
    let auth = useAuth();
    const [busy, setBusy] = useState(false)
    const [basket, setBasket] = useState(null)
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [id, setId] = useState(0)
    const now = new Date()
    const tomorrow = now
    tomorrow.setDate(now.getDate() + 1)

    let cartRef = useRef(null)
    const flattenDevices = (items) => {
        if(typeof items === 'undefined' || items === null){
            return []
        }
        var devicelist = []
        items.forEach((item) => {
            devicelist.push(item.device.id)
        })
        return devicelist
    }
    const addSet = (product_id) => {
        setBusy(true)
        if (cartRef) {
            cartRef.current.className = 'nav-link relative jello-vertical'
            setTimeout(() => {
                cartRef.current.className = 'nav-link relative'
            }, 500)
        }
        fetch(process.env.REACT_APP_API + '/baskets/set/'+product_id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(r => {
            if(Array.isArray(r.items)){
                r.items.sort(sortBasket)
            }
            setBasket(r)
            setId(id + 1)
            setBusy(false)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setBusy(false)
        });
        if (cartRef) {
            cartRef.current.className = 'nav-link relative jello-horizontal'
            setTimeout(() => {
                cartRef.current.className = 'nav-link relative'
            }, 500)
        }
    }
    const addDevice = (id, datum = null) => {
        setBusy(true)
        var devicelist = flattenDevices(basket.items)
        devicelist.push(id)
        basket.items = devicelist
        if (datum !== null) {
            basket.start = datum
            basket.end = new Date(basket.end)
        }
        if (cartRef) {
            cartRef.current.className = 'nav-link relative jello-vertical'
            setTimeout(() => {
                cartRef.current.className = 'nav-link relative'
            }, 500)
        }
        fetch(process.env.REACT_APP_API + '/baskets/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(basket)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(r => {
            if(Array.isArray(r.items)){
                r.items.sort(sortBasket)
            }
            setBasket(r)
            setId(id + 1)
            setBusy(false)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setBusy(false)
        });
        if (cartRef) {
            cartRef.current.className = 'nav-link relative jello-horizontal'
            setTimeout(() => {
                cartRef.current.className = 'nav-link relative'
            }, 500)
        }
    }
    const removeDevice = (id) => {
        setBusy(true)
        if (cartRef) {
            cartRef.current.className = 'nav-link relative jello-vertical'
            setTimeout(() => {
                cartRef.current.className = 'nav-link relative'
            }, 500)
        }
        fetch(process.env.REACT_APP_API + '/baskets/' + id, {
            method: 'DELETE',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(r => {
            if(Array.isArray(r.items)){
                r.items.sort(sortBasket)
            }
            setBasket(r)
            setId(id + 1)
            setBusy(false)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setBusy(false)
        });
    }
    const unbouncedUpdateCart = (field, value, forceUpdate) => {
        setBusy(true)
        var data = { ...basket }
        data[field] = value
        data.items = flattenDevices()
        fetch(process.env.REACT_APP_API + '/baskets/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(r => {
            if (forceUpdate) {
                if(Array.isArray(r.items)){
                    r.items.sort(sortBasket)
                }
                setBasket(r)
                setId(id + 1)
            }
            setBusy(false)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setBusy(false)
        });
    }
    const updateCart = useDebouncedCallback(unbouncedUpdateCart, 1000)
    const clearCart = (shallConfirm) => {
        if (!shallConfirm || window.confirm("Warenkorb zurücksetzen?")) {
            setBusy(true);
            fetch(process.env.REACT_APP_API + '/baskets/clear/', {
                method: 'DELETE',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + auth.token
                }
            }).then(async res => {
                if (!res.ok) {
                    const j = await res.json()
                    throw new Error({ message: j.detail })
                }
                return res.json()
            }).then(r => {
                setBusy(false)
                if(Array.isArray(r.items)){
                    r.items.sort(sortBasket)
                }
                setBasket(r)
                setId(id + 1)
            }).catch((e) => {
                setError({ message: e.name, detail: e.message });
                setBusy(false)
            });
        }
    }
    const isInCart = (device_id, date) => {
        if(basket === null) {
            return false
        }
        var found = false
        const start = new Date(basket.start)
        const end = new Date(basket.end)
        basket && Array.isArray(basket.items) && basket.items.forEach(item => {
            if (item.device && item.device.id === device_id && (date === false || (start <= date && end >= date))) {
                found = true
            }
        });
        return found
    }
    const sortBasket = (a, b) => {
        if(a.device.product.parent?.name === b.device.product.parent?.name){
            if(a.device.product.name === b.device.product.name) {
                return a.device.serial.localeCompare(b.device.serial)
            }
            return a.device.product.name.localeCompare(b.device.product.name)
        }
        return a.device.product.parent?.name.localeCompare(b.device.product.parent?.name)
    }
    const fetchCart = useCallback(() => {
        setBusy(true)
        fetch(process.env.REACT_APP_API + '/baskets/', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (r) => {
                    if (r.detail) {
                        setError({ message: r.detail })
                    } else {
                        if(Array.isArray(r.items)){
                            r.items.sort(sortBasket)
                        }
                        setBasket(r)
                    }
                    setIsLoaded(true)
                    setBusy(false)
                },
                (error) => {
                    setIsLoaded(true)
                    setError(error)
                    setBusy(false)
                }
            )
    }, [auth.token])
    useEffect(() => {
        if (auth.token && auth.token.length > 0) {
            fetchCart();
        }
    }, [auth.token, fetchCart])
    const renderModal = (showCart, setShowCart) => {
        return <CartModal id={id} show={showCart} setShow={setShowCart} basket={basket} removeDevice={removeDevice} clearCart={clearCart} updateCart={updateCart} flattenDevices={flattenDevices}/>
    }
    return {
        basket,
        addDevice,
        addSet,
        removeDevice,
        clearCart,
        updateCart,
        unbouncedUpdateCart,
        cartRef,
        isInCart,
        isLoaded,
        busy,
        error,
        renderModal
    }
}