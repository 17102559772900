export function fetchProducts(token, setProducts, setError, cb) {
    fetch(process.env.REACT_APP_API + '/products/short', {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
    })
        .then(res => res.json())
        .then(
            (res) => {
                setProducts(res.sort((a, b) => {return a.name.localeCompare(b.name);}));
                if(typeof cb === 'function'){
                    cb();
                }
            },
            (e) => {
                setError({ message: e.name, detail: e.message });
            }
        )
}