import React, { useState, lazy, useEffect, Suspense } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { Alert, Spinner, Container, FloatingLabel, Form, Button, Row, Col } from 'react-bootstrap'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'
import { fetchCategories } from '../../requests/Categories'

const MDEditor = lazy(() => import('@uiw/react-md-editor'));

export function ProductCreate() {
    let auth = useAuth()
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm()
    const [categories, setCategories] = useState([])
    const [invalidTeaser, setInvalidTeaser] = useState(false)
    const [teaser, setTeaser] = useState("")
    const [description, setDescription] = useState("")
    const [submitting, setSubmitting] = useState(false)
    const [saved, setSaved] = useState(null)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const validateTeaser = (t) => {
        const limit = 150
        setInvalidTeaser(t.length > limit)
        setTeaser(t.substring(0, limit))
    }
    const onSubmit = data => {
        setSubmitting(true);
        data.teaser = teaser
        data.description = description
        data.setparent = 0
        fetch(process.env.REACT_APP_API + '/products/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false);
            setSaved(true)
            setTimeout(function () {
                navigate({ pathname: "/admin/products/" + t.id });
            }, 1000);
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    useEffect(() => {
        fetchCategories(setCategories, setError, null)
    }, [])
    return (
        <Container className="d-flex flex-column">
            <h1 className="my-5"><FontAwesomeIcon icon={faVideo} /> Produkt anlegen</h1>
            <Row className="w-100">
                <Col>
                    <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    label="Name *"
                                    className="mb-3"
                                >
                                    <Form.Control {...register('name', { required: true })} type="text" placeholder="Name" required />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel
                                    label="Interne Nr. *"
                                    className="mb-3"
                                >
                                    <Form.Control {...register('serial', { required: true })} type="text" placeholder="Interne Nr." required />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    label="Typ"
                                    className="mb-3"
                                >
                                    <Form.Control {...register('typ', {})} type="text" placeholder="Typ" />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel
                                    label="Kategorie *"
                                    className="mb-3"
                                >
                                    <Form.Select {...register('category_id', { required: true, min: 1 })} aria-label="Kategorie" >
                                        <option value={0}>bitte wählen</option>
                                        {categories.map((p, i) => {
                                            return <option key={'productcategory' + p.id} value={p.id}>{p.name}</option>
                                        })}
                                    </Form.Select>
                                </FloatingLabel>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Group className="text-start" controlId="formBasicCheckbox">
                                            <Form.Check {...register('disabled', {})} inline value={true} defaultChecked={true} type="switch" label="versteckt" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="text-start" controlId="formBasicCheckbox">
                                            <Form.Check {...register('plannable', {})} inline value={true} defaultChecked={true} type="switch" label="im Planer anzeigen" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <FloatingLabel
                                    label="Cooldown (in Stunden)"
                                    className="mb-3"
                                >
                                    <Form.Control {...register('cooldown', {min: 0})} type="number" defaultValue={0} placeholder="Cooldown" />
                                </FloatingLabel>
                            </Col>

                        </Row>
                        <Row className="mb-4">
                            <Col className="text-start">
                                <Form.Label>Interne Bemerkungen</Form.Label>
                                <FloatingLabel
                                    label="Bemerkungen"
                                    className="mb-3"
                                >
                                    <Form.Control {...register('bemerkungen', {})} as="textarea" placeholder="Interne Bemerkungen" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Suspense fallback={<Spinner/>}>
                                    <Form.Label>Teaser <small>(max. 150 Zeichen)</small></Form.Label>
                                    <MDEditor
                                        value={teaser}
                                        onChange={validateTeaser}
                                        className={invalidTeaser ? 'is-invalid' : ''}
                                    />
                                    {invalidTeaser && <div className="invalid-feedback text-start">Bitte verwenden Sie maximal 150 Zeichen</div>}
                                </Suspense>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Suspense fallback={<Spinner/>}>
                                    <Form.Label>Beschreibung</Form.Label>
                                    <MDEditor
                                        value={description}
                                        onChange={setDescription}
                                    />
                                </Suspense>
                            </Col>
                        </Row>
                        <p className="text-end">
                            <small>* Pflichtfelder</small>
                        </p>
                        {error && (
                            <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                <Alert variant="danger">
                                    <span>
                                        {error.message} {error.detail}
                                    </span>
                                </Alert>
                            </FlashMessage>
                        )}
                        {saved && (
                            <FlashMessage duration={2000}>
                                <Alert variant="success">
                                    <span>
                                        Produkt erfolgreich angelegt
                                    </span>
                                </Alert>
                            </FlashMessage>
                        )}
                        <div className="d-grid gap-2">
                            <Button disabled={submitting} variant="primary" type="submit">
                                {submitting ? (
                                    <Spinner animation="border" variant="light" size="sm"></Spinner>
                                ) : "Anlegen"}
                            </Button>
                        </div>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}