import React, { useState } from "react"
import { Button } from 'react-bootstrap'
import { faTrash, faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"
import { deleteItem, primaryItem } from "./Util"

export function AdminImage({ token, product, image, i, setError }) {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <div className={(image.id === product.image_id ? 'danger ' : '') + "adminimage"} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <img width={128} onClick={() => setOpen(true)} src={process.env.REACT_APP_DOMAIN + "ul/products/th-" + image.id + '.' + image.ext} alt={"Anklicken zum Vergrößern"} className="img-fluid interactable" />
      <div className={(hover ? '' : 'd-none ') + "imagecontext"}>
        <Button variant={image.id === product.image_id ? 'danger' : 'primary'} className="btn-xs me-2" title="Als primäres Bild setzen">
          <FontAwesomeIcon icon={faHeart} onClick={() => primaryItem(token, product.id, 'images', image.id, setError)} />
        </Button>
        <Button className="btn-xs" onClick={() => deleteItem(token, product.id, 'images', image.id, i + 1, setError, null)} title="Bild löschen">
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={[{ src: process.env.REACT_APP_DOMAIN + "/ul/products/" + image.id + '.' + image.ext }]}
      />
    </div>
  );
}
