import { Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { checkIsBlocked } from './Util'

export function TimeGrid({ start, loaded, device }) {
    const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
    };
    var current = new Date(start.getFullYear(), start.getMonth(), 1);
    const TooltipLink = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            {children}
        </OverlayTrigger>
    );
    return (
        <div className={"timegrid" + (loaded ? '' : ' loading')} >
            <svg viewBox="0 0 644 99">
                <g className="monthlabels" transform="translate(20, 0)">
                    {[...Array(12).keys()].map((w) => {
                        var c = new Date(start.getFullYear(), start.getMonth() + w, 1)
                        return <text key={"monthlabel" + w} className="label" x={52.2 * w} y="10">{c.toLocaleString("de-DE", { month: 'short' })}</text>
                    })}
                </g>
                <g className="daylabels" transform="translate(0, 5)">
                    <text className="label" x="0" y="20">Mo</text>
                    <text className="label" x="0" y="42">Mi</text>
                    <text className="label" x="0" y="67">Fr</text>
                    <text className="label" x="0" y="90">So</text>
                </g>
                <g className="year" transform="translate(20, 15)">
                    {[...Array(52).keys()].map((w) => {
                        return (<g className={"week week" + w} key={"week" + w} transform={"translate(" + (12 * w) + ",0)"}>
                            {[...Array(7).keys()].map((d) => {
                                var day = current.toLocaleString("de-DE", options)
                                var isBlocked = checkIsBlocked(device, current)
                                if (w === 0 && (
                                    (
                                        (current.getDay() === 0 && d !== 6)) ||
                                    (current.getDay() - 1 > d))
                                ) {
                                    return ''
                                }
                                current.setDate(current.getDate() + 1)
                                return <TooltipLink title={day} id={"week" + w + "day" + d} key={"week" + w + "day" + d}>
                                    {isBlocked > 1 ? (
                                        <Link to={isBlocked ? "/admin/rentals/" + isBlocked : ''}>
                                            <g transform={"translate(0, " + (12 * d) + ")"}>
                                                <rect className={"day" + (isBlocked ? " blocked" : "")} rx="0" ry="0" width={10} height={10}></rect>
                                            </g>
                                        </Link>
                                    ) : (
                                        <g transform={"translate(0, " + (12 * d) + ")"}>
                                            <rect className={"day" + (isBlocked ? " blocked" : "")} rx="0" ry="0" width={10} height={10}></rect>
                                        </g>
                                    )}
                                </TooltipLink>
                            })}
                        </g>)
                    })}
                </g>
            </svg>
            {!loaded && (
                <Spinner className="spinner" />
            )}
        </div>
    )
}