export function loadLeihschein(id, token, setLoadinpdf) {
    setLoadinpdf(id)
    fetch(process.env.REACT_APP_API + '/rentals/leihschein/' + id, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            "Authorization": "Bearer " + token
        },
    })
        .then(res => res.blob())
        .then(blob => {
            var file = window.URL.createObjectURL(blob);
            window.open(file)
            setLoadinpdf(0)
        }, (_error) => {
            setLoadinpdf(0)
        });
}