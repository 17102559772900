import React, { useState, useEffect, useCallback, lazy, Suspense } from "react"
import { Alert, Container, Row, Col, Spinner } from 'react-bootstrap'
import { AdminRentals } from "./Rentals"
import FlashMessage from '../../components/FlashMessage'
import { useAuth } from '../../components/Auth'
import { useConfig } from '../../components/Config'
import { Time } from '../../components/Time'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

const LineChart = lazy(() => import('../../components/LineChart'));

export function Dashboard({ theme }) {
    let auth = useAuth()
    let config = useConfig()
    const [stats, setStats] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [errorid, setErrorId] = useState(0)
    const avgdur = (pos) => {
        if (stats.requests[pos].y === 0) {
            return 0
        }
        return ((stats.duration[pos].y / stats.requests[pos].y) * 1000).toFixed(4)
    }
    const fetchStats = useCallback(() => {
        fetch(process.env.REACT_APP_API + '/stats/', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (r) => {
                    if (r.detail) {
                        setError({ message: r.detail })
                        setErrorId(errorid + 1)
                        setLoading(false)
                    } else {
                        setStats(r)
                        setLoading(false)
                    }
                },
                (error) => {
                    setError(error)
                    setErrorId(errorid + 1)
                    setLoading(false)
                }
            )
    }, [auth.token, errorid])
    useEffect(() => {
        fetchStats()
    }, [fetchStats])
    if (loading) {
        return (
            <div className="text-center">
                <Spinner />
            </div>
        )
    }

    return (
        <Container>
            <h1>Dashboard</h1>
            {error && (
                <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                    <Alert variant="danger">
                        <span>
                            {error.message} {error.detail}
                        </span>
                    </Alert>
                </FlashMessage>
            )}
            <AdminRentals title="Neueste Vorgänge" limit={10} dashboard={true} />
            <AdminRentals title="Überfällige Vorgänge" limit={2} dashboard={true} overdue={true} defaultSort="end" defaultAsc="true" activefilters={[{ key: 'only_overdue', value: true }]} />
            <Container>
                <Row>
                    <Col xs={12} lg={5}>
                        <h2>Server Infos</h2>
                        <Row>
                            <Col>
                                <b>Online seit</b>:
                            </Col>
                            <Col>
                                {Time({
                                    date: stats.start,
                                    withTime: true
                                })}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>Loginversuche (letzten 3h)</b>:
                            </Col>
                            <Col>
                                {stats.logins[23].y}, {stats.logins[22].y}, {stats.logins[21].y}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>Antwortzeiten (ms)</b>:
                            </Col>
                            <Col>
                                {avgdur(23)}, {avgdur(21)}, {avgdur(20)}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>Serverload</b>:
                            </Col>
                            <Col>
                                {stats.load[0].toFixed(2)}, {stats.load[1].toFixed(2)}, {stats.load[2].toFixed(2)}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>System-Memory</b>:
                            </Col>
                            <Col>
                                {stats.memory.used}MB / {stats.memory.total}MB
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>App-Memory</b>:
                            </Col>
                            <Col>
                                {(stats.memory.app/1024).toFixed(0)}MB
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>Letzter Cron (stündlich)</b>:
                            </Col>
                            <Col>
                                {Time({
                                    date: stats.cron.hourly,
                                    withTime: true
                                })}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>Letzter Cron (täglich)</b>:
                            </Col>
                            <Col>
                                {Time({
                                    date: stats.cron.daily,
                                    withTime: true
                                })}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={7}>
                        <Suspense fallback={<Spinner />}>
                            <LineChart data={[
                                {
                                    "id": "Requests/h",
                                    "data": stats.requests
                                },
                            ]} theme={theme} />
                        </Suspense>
                    </Col>
                </Row>
                <h2 className="my-2">Konfiguration (Server/Client)</h2>
                <Row>
                    <Col>
                        Variante:
                    </Col>
                    <Col>
                        {stats.configuration.variant === 'ok' ? "Offener Kanal" : "VHS"} /
                        {process.env.REACT_APP_FLAVOUR === 'ok' ? "Offener Kanal" : "VHS"}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Externe Registrierung:
                    </Col>
                    <Col>
                        {stats.configuration.registration ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>} / 
                        {config.registration ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Automatische Erinnerung bei verpäteter Rückgabe (in Tagen, 0=keine):
                    </Col>
                    <Col>
                        {stats.configuration.overdue_days}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Automatische Erinnerung über verspätete Rückgaben:
                    </Col>
                    <Col>
                        {stats.configuration.overdue_reminder_admins ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}
                    </Col>
                </Row>
            </Container>
        </Container >
    )
}