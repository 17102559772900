import React, { useContext, createContext, useState, useEffect } from "react"
import { useCookies } from "react-cookie"
import packageJson from '../../package.json'
import { devlog } from './Util'

export const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            <RefreshAuthComponent/>
            {children}
        </authContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(authContext);
};
function parseJwt (token) {
    if(typeof token === "undefined" || token === "undefined"){
        return false
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};
function useProvideAuth() {
    const [cookies, setCookie, removeCookie] = useCookies(['essential', 'auth', 'session'])
    const [token, setToken] = useState(
        cookies['auth'] || ''
    );
    const [session, setSession] = useState(
        cookies['session'] || ''
    );
    const [refreshingToken, setRefreshingToken] = useState(false);
    const [error, setError] = useState('');

    const errorDuration = 5000;
    var scopes = [];
    var username = '';
    var id = '';
    var expires = 0;
    var session_expires = 0;

    const ekonAuth = {
        async signin(email, password, cb) {
            const payload = {
                username: email,
                password: password,
                grant_type: 'password'
            };
            const formData = Object.keys(payload).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(payload[key]);
              }).join('&');
            await fetch(process.env.REACT_APP_API+'/token', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Client": "ausleihe-fe-"+packageJson.version
                },
                body: formData
            }).then(response => {
                if([500, 502].includes(response.status)){
                    throw new Error('Server Fehler: '+response.status);
                }
                return response.json().then(data => ({ok: response.ok, status: response.status, body: data}))
            }).then(data => {
                if([401,403].includes(data.status)){
                    throw new Error('Zugang verweigert: '+data.body.detail);
                }
                if(!data.ok){
                    throw new Error('Netzwerkfehler');
                }
                cb(data.body,null);
            }).catch((error) => {
                cb(null,String(error));
            });
        },
        signout(cb) {
            ekonAuth.token = '';
            ekonAuth.session = '';
            cb();
        },
        error(){
            return error;
        },
        setError(msg){
            setError(msg);
        }
    };
    const signin = (email, password, cb) => {
        return ekonAuth.signin(email, password, (data, error) => {
            if(data === null){
                if(error === null){
                    error = 'Keine Serverantwort';
                }
            } else {
                ekonAuth.token = data.access_token;
                if(cookies['essential']) {
                    setCookie('auth', ekonAuth.token, {
                        path: '/',
                        sameSite: true,
                        secure: true
                    })
                }
                setToken(data.access_token);
            }
            cb(data, error);
        });
    };
    const signout = cb => {
        return ekonAuth.signout(() => {
            removeCookie('auth', { path: '/' })
            removeCookie('session', { path: '/' })
            setToken(null);
            setSession(null);
            cb();
        });
    };
    const startSession = () => {
        devlog("preparing to start session token")
        if(!token || token.length === 0 || token === "undefined" || refreshingToken){
            return false
        }
        setRefreshingToken(true)
        devlog("starting session token")
        const ac = new AbortController()
        fetch(process.env.REACT_APP_API+'/generate_refresh_token', {
            signal: ac.signal,
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Client": "ausleihe-fe-"+packageJson.version,
                "Authorization": "Bearer " + token
            }
        }).then(async res => {
            if([500, 502].includes(res.status)){
                throw new Error('Server Fehler: '+res.status);
            }
            const data = await res.json();
            return ({ ok: res.ok, status: res.status, body: data });
        }).then(data => {
            if([401,403].includes(data.status)){
                throw new Error('Zugang verweigert: '+data.body.detail);
            }
            if(!data.ok){
                throw new Error('Netzwerkfehler');
            }
            if(cookies['essential']) {
                setCookie('session', data.body.access_token, {
                    path: '/',
                    sameSite: true,
                    secure: true
                })
            }
            setSession(data.body.access_token)
            setRefreshingToken(false)
            devlog("successfully started session token")
        }).catch(() => {
            if (!ac.signal.aborted) {
                setRefreshingToken(false)
                removeCookie('session', { path: '/' })
                setSession(null);
            }
        });
    }
    const refreshToken = () => {
        devlog("preparing to refresh token")
        if(!session || session.length === 0 || session === "undefined" || refreshingToken){
            return false
        }
        if((expires - Date.now() > 99999)){
            devlog("token valid until "+new Date(expires).toLocaleTimeString())
            return 
        }
        setRefreshingToken(true)
        devlog("refreshing token")
        const ac = new AbortController()
        fetch(process.env.REACT_APP_API+'/refresh_token', {
            signal: ac.signal,
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Client": "ausleihe-fe-"+packageJson.version,
                "Authorization": "Bearer " + session
            }
        }).then(async res => {
            if([500, 502].includes(res.status)){
                throw new Error('Server Fehler: '+res.status);
            }
            const data = await res.json();
            return ({ ok: res.ok, status: res.status, body: data });
        }).then(data => {
            if([401,403].includes(data.status)){
                throw new Error('Zugang verweigert: '+data.body.detail);
            }
            if(!data.ok){
                throw new Error('Netzwerkfehler');
            }
            if(cookies['essential']) {
                setCookie('auth', data.body.access_token, {
                    path: '/',
                    sameSite: true,
                    secure: true
                })
            }
            setToken(data.body.access_token)
            setRefreshingToken(false)
            devlog("successfully refreshed token")
        }).catch(() => {
            if (!ac.signal.aborted) {
                setRefreshingToken(false)
                removeCookie('auth', { path: '/' })
                removeCookie('session', { path: '/' })
                setToken(null);
                setSession(null);
            }
        });
    }
    if(typeof token === 'string' && token.length > 0){
        let decodedToken = parseJwt(token);
        username = decodedToken.sub;
        id = decodedToken.id;
        expires = decodedToken.exp * 1000;
        if(Array.isArray(decodedToken.scopes)){
            scopes = decodedToken.scopes;
        }
        if(token === false || Date.now() > expires){
            removeCookie('auth', { path: '/' })
            setToken(null);
        }
    }
    if(typeof session === 'string' && session.length > 0) {
        let decodedToken = parseJwt(session);
        session_expires = decodedToken.exp * 1000;
        if(session === false || Date.now() > session_expires){
            removeCookie('session', { path: '/' })
            setSession(null);
        } else {
            if(!refreshingToken && token.length === 0){
                refreshToken()
            }
        }
    } else {
        startSession()
    }
    return {
        token,
        signin,
        signout,
        refreshToken,
        setError,
        errorDuration,
        error,
        scopes,
        username,
        id,
        expires,
        startSession
    };
}

function RefreshAuthComponent() {
    let auth = useAuth();
    useEffect(() => {
        const refreshInterval = setInterval(() => {
            auth.refreshToken()
        }, process.env.REACT_APP_TOKEN_REFRESH_INTERVAL)
        return () => {
            clearInterval(refreshInterval)
        }
    })
    return <></>
}