import { Helmet, HelmetProvider } from 'react-helmet-async'
export function HelmetComponent({ theme }) {
    return (
        <HelmetProvider>
            <Helmet>
                <html data-color-mode={theme ? theme : 'dark'} data-bs-theme={theme ? theme : 'dark'} />
                <meta charSet="utf-8" />
                <title>{process.env.REACT_APP_NAME}</title>
                <link rel="canonical" href="https://www.domain.tld" />
            </Helmet>
        </HelmetProvider>
    )
}