import { devlog } from '../components/Util'
export function fetchStati(abortController, setStati, setIsLoaded, setError) {
    fetch(process.env.REACT_APP_API + '/rentals/states/', {
        signal: abortController.signal,
        method: 'GET',
        cache: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
    })
        .then(res => res.json())
        .then(
            (res) => {
                setStati(res.sort((a, b) => {return a.id > b.id;}));
                setIsLoaded(true);
            },
            (e) => {
                setIsLoaded(true);
                if (abortController.signal.aborted) {
                    devlog('The user aborted the request');
                } else {
                    setError({ message: e.name, detail: e.message });
                }
            }
        )
}