export function fetchSlug (slug, setPage, setIsLoaded, setError) {
    fetch(process.env.REACT_APP_API + '/pages/slug/' + slug, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
    })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.detail) {
                    setError({ message: result.detail })
                } else {
                    setPage(result)
                }
                setIsLoaded(true)
            },
            (error) => {
                setIsLoaded(true)
                setError(error)
            }
        )
}