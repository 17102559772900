import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { Alert, Spinner, Container, FloatingLabel, Form, Button, Row, Col } from 'react-bootstrap'
import { faBox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'

export function CategoryCreate() {
    let auth = useAuth()
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [tag, setTag] = useState([])
    const [saved, setSaved] = useState(null)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)

    const onSubmit = data => {
        setSubmitting(true);
        fetch(process.env.REACT_APP_API + '/categories/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false);
            setSaved(true)
            setTag(t)
            setTimeout(function () {
                navigate({ pathname: "/admin/categories/" });
            }, 1000);
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }

    return (
        <Container className="d-flex flex-column">
            <h1 className="my-5"><FontAwesomeIcon icon={faBox} /> Kategorie anlegen</h1>
            <Row className="w-100">
                <Col>
                    <form className="form-signin text-center w-100" onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" {...register('id', { required: true })} value={0} />
                        <FloatingLabel
                            label="Name *"
                            className="mb-3"
                        >
                            <Form.Control {...register('name', { required: true })} defaultValue={tag.name} type="text" placeholder="Name" required />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Sortierung *"
                            className="mb-3"
                        >
                            <Form.Control {...register('sort', { required: true, min: 0 })} defaultValue={tag.sort} type="number" placeholder="Sortierung" required />
                        </FloatingLabel>
                        <p className="text-end">
                            <small>* Pflichtfelder</small>
                        </p>
                        {error && (
                            <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                <Alert variant="danger">
                                    <span>
                                        {error.message} {error.detail}
                                    </span>
                                </Alert>
                            </FlashMessage>
                        )}
                        {saved && (
                            <FlashMessage duration={2000}>
                                <Alert variant="success">
                                    <span>
                                        Kategorie erfolgreich angelegt
                                    </span>
                                </Alert>
                            </FlashMessage>
                        )}
                        <div className="d-grid gap-2">
                            <Button disabled={submitting} variant="primary" type="submit">
                                {submitting ? (
                                    <Spinner animation="border" variant="light" size="sm"></Spinner>
                                ) : "Anlegen"}
                            </Button>
                        </div>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}