export function fetchProduct(id, token, setProduct, setError, setTeaser, setDescription, setIsLoaded, reset) {
    fetch(process.env.REACT_APP_API + '/products/' + id, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
    })
        .then(res => res.json())
        .then(
            (res) => {
                if (res.detail) {
                    setError({ message: res.detail })
                } else {
                    setProduct(res)
                    if (reset instanceof Function) {
                        reset({
                            category_id: res.category.id
                        })
                    }
                    if (setTeaser instanceof Function) {
                        setTeaser(res.teaser)
                    }
                    if (setDescription instanceof Function) {
                        setDescription(res.description)
                    }
                }
                if (setIsLoaded instanceof Function) {
                    setIsLoaded(true)
                } 
            },
            (error) => {
                if (setIsLoaded instanceof Function) {
                    setIsLoaded(true)
                }
                setError(error)
            }
        )
}