import React, { useEffect, useState } from "react"
import { faRetweet, faFilePdf, faSpinner, faSnowflake } from '@fortawesome/free-solid-svg-icons'
import { Alert, Spinner } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { useAuth } from '../../components/Auth'
import { ObjectListing } from '../../components/ObjectListing'
import { Time } from '../../components/Time'
import FlashMessage from '../../components/FlashMessage'
import { fetchStati } from '../../requests/Stati'
import { loadLeihschein } from '../../requests/Leihschein'
import { getFullname } from '../../components/Util'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function AdminRentals({ title, limit, dashboard, overdue, defaultSort, defaultAsc, activefilters }) {
    let auth = useAuth();
    const [stati, setStati] = useState([])
    const [loadingpdf, setLoadinpdf] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(null)
    var filters = [{
        label: 'überfällige',
        boolean: true,
        default: overdue,
        requestAttribute: 'only_overdue',
    }]
    useEffect(() => {
        const abortController = new AbortController()
        fetchStati(abortController, setStati, setIsLoaded, setError)
        return () => {
            abortController.abort();
        }
    }, [])
    if (stati.length > 0) {
        filters.push({
            label: 'Status',
            requestAttribute: 'status_id',
            toOption: (u) => {
                return u.name
            },
            options: stati
        })
    }
    const rowClass = (i) => {
        if (i.status.id === 4) {
            return 'done';
        }
        if (i.status.id === 5) {
            return 'storno';
        }
        if (i.status.id === 3 && i.end) {
            const d = Date.parse(i.end);
            if (Date.now() - d > 86400) {
                return 'overdue';
            } else if (Date.now() - d > 0) {
                return 'late';
            }
        }
        return '';
    }
    const attributes = [
        {
            key: "id",
            label: "ID",
            order: true,
        },
        {
            key: "created",
            label: "Eingangsdatum",
            order: true,
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            }
        },
        {
            key: "start",
            label: "Von",
            order: true,
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            }
        },
        {
            key: "end",
            label: "Bis",
            order: true,
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            }
        },
        {
            key: "lastname",
            parent: "user",
            label: "Nutzer",
            order: true,
            format: (u, r) => {
                return <Link to={'/admin/users/' + r.user.uuid}>{getFullname(r.user)}<br/>{"<"}{r.user.email}{">"}</Link>
            }
        },
        {
            key: "einrichtung",
            parent: "user",
            label: "Einrichtung",
            order: true,
            format: (u, r) => {
                return r.user.einrichtung ? r.user.einrichtung : ''
            }
        },
        {
            key: "name",
            parent: "status",
            label: "Status",
            order: true,
            format: (u, r) => {
                return <>
                    {u}
                    {r.user.uuid === process.env.REACT_APP_COOLDOWN_USER && (
                        <FontAwesomeIcon icon={faSnowflake} className="ms-2" />
                    )}
                </>
            }
        },
    ];
    const actions = [
        {
            icon: (id) => loadingpdf === id ? faSpinner : faFilePdf,
            className: (id) => loadingpdf === id ? 'spin' : '',
            title: 'Leihschein',
            click: (id) => {
                loadLeihschein(id, auth.token, setLoadinpdf)
            },
        }
    ]
    if (error) {
        return <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true}>
            <Alert variant="danger">
                <span>
                    {error.message} {error.detail}
                </span>
            </Alert>
        </FlashMessage>
    } else if (!isLoaded) {
        return <div className="text-center"><Spinner /></div>;
    } else {
        return <ObjectListing headersize={4} pagination={!dashboard} itemsPerPage={limit ? limit : 20} objectname="rentals" rowClass={rowClass} showTitle={true} admin={true} title={title ? title : "Vorgänge"} icon={faRetweet} attributes={attributes} defaultSort={defaultSort ? defaultSort : "created"} defaultAsc={defaultAsc ? defaultAsc : "false"} add={false} useuuid={false} edit={true} delete={false} filters={filters} activefilters={activefilters} search={!dashboard} headerButtons={[]} actions={actions} />
    }
}