import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../../components/ObjectListing'
import { Time } from '../../components/Time'

export function Pages() {
    var filters = []

    const attributes = [
        {
            key: "slug",
            label: "Slug",
            order: true,
        },
        {
            key: "name",
            label: "Name",
            order: true,
        },
        {
            key: "disabled",
            label: "Online",
            order: true,
            format: (d) => {
                return d ? <FontAwesomeIcon className="text-body-tertiary" icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />
            }
        },
        {
            key: "modified",
            label: "letzte Änderung",
            order: true,
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            }
        },
    ];
    return <ObjectListing objectname="pages" noCache={true} showTitle={true} admin={true} title="Seiten" icon={faLink} attributes={attributes} defaultSort="name" defaultAsc="true" add={true} useuuid={false} edit={true} delete={true} filters={filters} search={true} headerButtons={[]} />
}