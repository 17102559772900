import React from "react"
import { faList } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../../components/ObjectListing'
import { Time } from '../../components/Time'

export function Logs() {
    const attributes = [
        {
            key: 'id',
            label: "#"
        },
        {
            key: 'created',
            label: "Datum",
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            }
        },
        {
            key: 'area',
            label: "Bereich"
        },
        {
            key: 'user',
            label: "User",
            format: (u) => {
                if(u == null){
                    return ''
                }
                if(u.email.length > 0){
                    return u.email
                }
                return u.alias
            }
        },
        {
            key: "message",
            label: "Nachricht"
        }
    ];
    return <ObjectListing itemsPerPage={100} showTitle={true} objectname="logs" title="Logs" icon={faList} attributes={attributes} defaultSort="created" defaultAsc="false" add={false} edit={false} delete={false} search={true}/>
}