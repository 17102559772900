import React, { useEffect, useState, lazy, Suspense } from "react"
import { fetchSlug } from '../requests/Slug';
import { Alert, Container, Row, Col, Spinner } from 'react-bootstrap'
import { Products } from './Products';
import FlashMessage from '../components/FlashMessage'

const ReactMarkdown = lazy(() => import('react-markdown'));

export function Home() {
    const [page, setPage] = useState([])
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        fetchSlug("home", setPage, setIsLoaded, setError);
    }, [])
    if (!isLoaded) {
        return <Row>
            <Col className="text-center">
                <Spinner />
            </Col>
        </Row>
    }
    return (
        <Container id="home">
            <Row>
                <Col>
                    {error ? (
                        <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true}>
                            <Alert variant="danger">
                                <span>
                                    {error.message} {error.detail}
                                </span>
                            </Alert>
                        </FlashMessage>
                    ) : (
                        <>
                            <h1>
                                {page.name}
                            </h1>
                            <Suspense fallback={<Spinner />}>
                                <ReactMarkdown>
                                    {page.text}
                                </ReactMarkdown>
                            </Suspense>

                        </>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Products />
                </Col>
            </Row>
        </Container>
    )
}