import React, { useContext, createContext} from "react"

export const configContext = createContext();

export function ProvideConfig({ children }) {
    const auth = useProvideConfig();
    return (
        <configContext.Provider value={auth}>
            {children}
        </configContext.Provider>
    );
}

export const useConfig = () => {
    return useContext(configContext);
};

function useProvideConfig() {
    var config = {}
    if(process.env.REACT_APP_FLAVOUR === 'ok'){
        config = {
            'registration': true 
        }
    } else {
        config = {
            'registration': false
        }
    }
    return config;
}
