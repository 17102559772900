import { devlog } from '../components/Util'
export function fetchReasons(abortController, setReasons, setError, cb = null) {
    fetch(process.env.REACT_APP_API + '/rentals/reasons/', {
        signal: abortController.signal,
        method: 'GET',
        cache: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
    })
        .then(res => res.json())
        .then(
            (res) => {
                setReasons(res.sort((a, b) => {return a.name.localeCompare(b.name);}));
                if(cb !== null){
                    cb()
                }
            },
            (e) => {
                if (abortController.signal.aborted) {
                    devlog('The user aborted the request');
                } else {
                    setError({ message: e.name, detail: e.message });
                }
            }
        )
}