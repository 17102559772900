import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useAuth } from '../components/Auth'
import { Logout } from '../components/Logout'
import FlashMessage from '../components/FlashMessage'
import { Button, Container, Row, Col, Spinner, Alert, Form, FloatingLabel } from 'react-bootstrap'

export function Me() {
    let auth = useAuth();
    const { register, handleSubmit, getValues, formState: { errors } } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [user, setUser] = useState([])
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)
    const [saved, setSaved] = useState(false)
    const onSubmit = data => {
        setSubmitting(true);
        fetch(process.env.REACT_APP_API + '/users/me/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(u => {
            setSubmitting(false)
            setUser(u)
            setSaved(true)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    const fetchMe = function (token) {
        fetch(process.env.REACT_APP_API + '/users/me/', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.detail) {
                        setError({ message: result.detail })
                    } else {
                        setUser(result)
                    }
                    setIsLoaded(true)
                },
                (error) => {
                    setIsLoaded(true)
                    setError(error)
                }
            )
    }
    useEffect(() => {
        fetchMe(auth.token);
    }, [auth])
    return (
        <Container>
            <Row>
                <Col>
                    {error && (
                        <Alert variant="danger">
                            <p>{error}</p>
                        </Alert>
                    )}
                    {isLoaded ? (
                        <>
                            <h1 className="my-5">Howdy {user.firstname} {user.lastname}!</h1>
                            <Row>
                                <Col>
                                    <form className="form-signin text-center w-100" onSubmit={handleSubmit(onSubmit)}>
                                        <FloatingLabel
                                            label="Email *"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('email', { required: true })} defaultValue={user.email} type="email" placeholder="name@example.com" autoFocus required />
                                        </FloatingLabel>
                                        {!user.email_confirmed && <Alert variant="warning" className="text-start">Ihre Email ist noch nicht bestätigt</Alert>}
                                        <FloatingLabel
                                            label="Telefon"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('tel', {})} defaultValue={user.tel} type="text" placeholder="Telefon" />
                                        </FloatingLabel>
                                        <FloatingLabel
                                            label="Passwort ändern"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('password', {})} type="password" placeholder="Passwort ändern" />
                                        </FloatingLabel>
                                        {errors.password && <Alert variant="danger" className="text-start">Bitte verwenden Sie mindestens 8 Zeichen</Alert>}
                                        <FloatingLabel
                                            label="Passwort Verfifikation"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('password2', { validate: value => value === getValues("password") })} type="password" placeholder="Passwort Verfifikation" />
                                        </FloatingLabel>
                                        {errors.password2 && <Alert variant="danger" className="text-start">Bitte überprüfen Sie die Schreibweise Ihres Passwortes</Alert>}
                                        <FloatingLabel
                                            label="Vorname *"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('firstname', { required: true })} defaultValue={user.firstname} type="text" placeholder="Vorname" required />
                                        </FloatingLabel>
                                        <FloatingLabel
                                            label="Nachname *"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('lastname', { required: true })} defaultValue={user.lastname} type="text" placeholder="Nachname" required />
                                        </FloatingLabel>
                                        <FloatingLabel
                                            label="Einrichtung"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('einrichtung', {})} defaultValue={user.einrichtung} type="text" placeholder="Einrichtung" />
                                        </FloatingLabel>
                                        <Row>
                                            <Col>
                                                <FloatingLabel
                                                    label="PLZ"
                                                    className="mb-3"
                                                >
                                                    <Form.Control {...register('plz', {})} defaultValue={user.plz} type="text" placeholder="PLZ" />
                                                </FloatingLabel>
                                            </Col><Col>
                                                <FloatingLabel
                                                    label="Ort"
                                                    className="mb-3"
                                                >
                                                    <Form.Control {...register('ort', {})} defaultValue={user.ort} type="text" placeholder="Ort" />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel
                                                    label="Straße"
                                                    className="mb-3"
                                                >
                                                    <Form.Control {...register('street', {})} defaultValue={user.street} type="text" placeholder="Straße" />
                                                </FloatingLabel>
                                            </Col><Col>
                                                <FloatingLabel
                                                    label="Hausnummer"
                                                    className="mb-3"
                                                >
                                                    <Form.Control {...register('streetnum', {})} defaultValue={user.streetnum} type="text" placeholder="Hausnummer" />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <p className="text-end">
                                            <small>* Pflichtfelder</small>
                                        </p>

                                        {error && (
                                            <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                                <Alert variant="danger">
                                                    <span>
                                                        {error.message} {error.detail}
                                                    </span>
                                                </Alert>
                                            </FlashMessage>
                                        )}
                                        {saved && (
                                            <FlashMessage duration={2000}>
                                                <Alert variant="success">
                                                    <span>
                                                        Daten erfolgreich gespeichert
                                                    </span>
                                                </Alert>
                                            </FlashMessage>
                                        )}
                                        <div className="d-grid gap-2">
                                            <Button disabled={submitting} variant="primary" type="submit">
                                                {submitting ? (
                                                    <Spinner animation="border" variant="light" size="sm"></Spinner>
                                                ) : "Aktualisieren"}
                                            </Button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="text-end">
                                    <Logout />
                                </Col>
                            </Row>

                        </>
                    ) :
                        <div className="text-center">
                            <Spinner />
                        </div>
                    }
                </Col>
            </Row>
        </Container>
    )
}