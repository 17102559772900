import { Container } from 'react-bootstrap'
import { RegisterForm } from '../components/RegisterForm'

export function Register() {
    return (
        <Container id="register" className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="mt-5">Registrierung</h1>
            <div className="w-50 mt-5">
                <RegisterForm/>
            </div>
        </Container>
    )
}