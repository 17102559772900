import { useCookies } from "react-cookie"
import { Alert, Row, Col } from 'react-bootstrap'

export function Video({ video }) {
    const [cookies, removeCookie] = useCookies(['google'])

    const isYoutube = video.url.indexOf('https://youtu') === 0 || video.url.indexOf('https://www.youtu') === 0
    if(isYoutube){
        const parts = video.url.split("/");
        const params =  parts[parts.length - 1]
        video.url = "https://www.youtube.com/embed/"+params[0]
    }
    const showBanner = () => {
        removeCookie('essential', 0, {
            path: '/'
        })
    }

    return (
        <Row>
            <Col>
                {!isYoutube || cookies['google'] ? (
                    isYoutube ? (
                        <iframe className="w-100" title={video.name} src={video.url} width={560} height={315} allowFullScreen={true} loading="lazy" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"/>
                    ) : (
                        <video controls autoPlay muted>
                            <source src={video.url} type="video/mp4" />
                        </video>
                    )
                ) : (
                    <Alert>
                        <p>Bitte <button className="link" onClick={showBanner}>akzeptieren</button> Sie die Speicherung von Google/Youtube Cookies</p>
                    </Alert>
                )}
            </Col>
        </Row>
    )
}