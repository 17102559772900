import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Modal, FloatingLabel, Form, Button, Alert, Spinner } from 'react-bootstrap'
import { useDebouncedCallback } from 'use-debounce'
import { useAuth } from './Auth'
import FlashMessage from './FlashMessage'

export function ProductChildModal({ product, show, setShow, fetchProduct, setProduct, setIsLoaded }) {
    let auth = useAuth();
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const [searchingProduct, setSearchingProduct] = useState(false)
    const [products, setProducts] = useState([])
    const searchProducts = useDebouncedCallback((p) => {
        if (p.length < 2) {
            return true
        }
        setSearchingProduct(true)
        fetch(process.env.REACT_APP_API + '/products/search', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify({ product: p })
        }).then(async res => {
            if (!res.ok) {
                throw new Error(res.detail)
            } else {
                return res.json()
            }
        }).then(ps => {
            setSearchingProduct(false)
            setProducts(ps)
            if (ps.length === 0) {
                reset({
                    product_id: 0
                })
            }

        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSearchingProduct(false)
        });
    }, 300)
    const onSubmit = data => {
        setSubmitting(true);
        fetch(process.env.REACT_APP_API + '/products/'+product.id+'/'+data.product_id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false);
            setShow(false)
            fetchProduct(product.id, auth.token, setProduct, setError, null, null, setIsLoaded)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Produkt verknüpfen</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <FloatingLabel
                        label="Produkt suchen"
                        className="mb-3"
                    >
                        <Form.Control type="text" placeholder="Produkt suchen" onKeyUp={(e) => searchProducts(e.target.value)} onChange={(e) => searchProducts(e.target.value)} autoFocus />
                    </FloatingLabel>

                    {searchingProduct ? <Spinner className="mt-3" /> : (
                        <FloatingLabel
                            label="Produkt *"
                            className="mb-3"
                        >
                            {products.length > 0 ? (
                                <Form.Select {...register('product_id', { required: true, min: 1 })} defaultValue={0} className={errors.product_id ? 'is-invalid' : ''} aria-label="Produkt">
                                    <option value="0">{products.length} Treffer</option>
                                    {products.map((p, i) => {
                                        return <option key={'productoption' + p.id} value={p.id}>{p.name} ({p.serial})</option>
                                    })}
                                </Form.Select>
                            ) : (
                                <Form.Select {...register('product_id', { required: true, min: 1 })} defaultValue={0} className={'is-invalid'} aria-label="Produkt">
                                    <option value="0">keine Treffer</option>
                                </Form.Select>
                            )}
                        </FloatingLabel>
                    )}
                    {error && (
                        <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                            <Alert variant="danger">
                                <span>
                                    {error.message} {error.detail}
                                </span>
                            </Alert>
                        </FlashMessage>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Schließen
                    </Button>
                    <Button variant="primary" disabled={submitting} type="submit">Hinzufügen</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}