import React from "react"
import { faBox } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../../components/ObjectListing'

export function Categories() {
    var filters = []

    const attributes = [
        {
            key: "name",
            label: "Name",
            order: false,
        },
        {
            key: "sort",
            label: "Sortierung",
            order: false,
        }
    ];
    return <ObjectListing objectname="categories" noCache={true} showTitle={true} admin={true} title="Kategorien" icon={faBox} attributes={attributes} defaultSort="name" defaultAsc="true" add={true} useuuid={false} edit={true} delete={true} filters={filters} search={true} headerButtons={[]} />
}