import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Modal, FloatingLabel, Form, Button, Alert } from 'react-bootstrap'
import { useAuth } from './Auth'
import FlashMessage from './FlashMessage'

export function ItemModal({device, show, setShow, fetchDevice}) {
    let auth = useAuth();
    const { register, handleSubmit } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const onSubmit = data => {
        setSubmitting(true);
        fetch(process.env.REACT_APP_API + '/items/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false);
            setShow(false)
            fetchDevice(device.id, auth.token)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            centered
            >
            <form onSubmit={handleSubmit(onSubmit)}>
             <input {...register('device_id', { required: true })} type="hidden" value={device.id}/>
            <Modal.Header closeButton>
                <Modal.Title>Komponente hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FloatingLabel
                    label="Name *"
                    className="mb-3"
                >
                    <Form.Control {...register('name', { required: true })} type="text" placeholder="Name" required />
                </FloatingLabel>
                <FloatingLabel
                    label="Seriennummer"
                    className="mb-3"
                >
                    <Form.Control {...register('serial')} type="text" placeholder="Seriennummer" />
                </FloatingLabel>
                <FloatingLabel
                    label="Inventarnummer"
                    className="mb-3"
                >
                    <Form.Control {...register('inventar')} type="text" placeholder="Inventarnummer" />
                </FloatingLabel>
                {error && (
                    <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                        <Alert variant="danger">
                            <span>
                                {error.message} {error.detail}
                            </span>
                        </Alert>
                    </FlashMessage>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Schließen
                </Button>
                <Button variant="primary" disabled={submitting} type="submit">Speichern</Button>
            </Modal.Footer>
            </form>
        </Modal>
    );
}