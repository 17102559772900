import { Link } from "react-router-dom"
import { Alert, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFrownOpen } from '@fortawesome/free-regular-svg-icons'

export function NotFound() {
    return (
        <Container>
            <Alert variant="secondary" key="">
            <h2>404 <FontAwesomeIcon icon={faFrownOpen}/></h2>
            <p>Sorry, die angefordere Seite existiert nicht</p>
            <p>
                <Link className="alert-link" to="/">
                    zurück
                </Link>
            </p>
            </Alert>
        </Container>
    );
}