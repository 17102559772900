import React from "react"
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../../components/ObjectListing'

export function Tags() {
    var filters = []

    const attributes = [
        {
            key: "name",
            label: "Name",
            order: true,
        }
    ];
    return <ObjectListing objectname="tags" noCache={true} showTitle={true} admin={true} title="Tags" icon={faTags} attributes={attributes} defaultSort="name" defaultAsc="true" add={true} useuuid={false} edit={true} delete={true} filters={filters} search={true} headerButtons={[]} />
}