import React from "react"
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../../components/ObjectListing'

export function Locations() {
    const attributes = [
        {
            key: 'name',
            label: "Name"
        },
        {
            key: 'plz',
            label: "PLZ",
        },
        {
            key: 'ort',
            label: "Ort"
        }
    ];
    return <ObjectListing showTitle={true} objectname="locations" title="Standorte" admin={true} icon={faBuilding} attributes={attributes} defaultSort="name" defaultAsc="true" add={true} edit={true} delete={true} search={true}/>
}