import React, { useEffect, useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import { faRetweet, faEye } from '@fortawesome/free-solid-svg-icons'
import { Alert, Spinner } from 'react-bootstrap'
import { ObjectListing } from '../components/ObjectListing'
import { Time } from '../components/Time'
import FlashMessage from '../components/FlashMessage'
import { devicesort } from '../components/Util'
import { fetchStati } from '../requests/Stati'

export function Rentals() {
    let navigate = useNavigate();
    const [stati, setStati] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(null)
    var filters = []

    useEffect(() => {
        const abortController = new AbortController()
        fetchStati(abortController, setStati, setIsLoaded, setError)
        return () => {
            abortController.abort();
        }
    }, [])
    if (stati.length > 0) {
        filters.push({
            label: 'Status',
            requestAttribute: 'status_id',
            toOption: (u) => {
                return u.name
            },
            options: stati
        })
    }
    const attributes = [
        {
            key: "id",
            label: "ID",
            order: true,
        },
        {
            key: "created",
            label: "Datum",
            order: true,
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            }
        },
        {
            key: "start",
            label: "Von",
            order: true,
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            }
        },
        {
            key: "end",
            label: "Bis",
            order: true,
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            }
        },
        {
            key: "devices",
            label: "Geräte",
            order: false,
            format: (devices) => {
                if (devices.length === 0) return
                return (
                    <ul>
                        {devices.sort(devicesort).map((d) => {
                            return (<li key={"customerrentaldevice"+d.id}><Link to={"/products/"+d.product_id}>{d.name}</Link></li>)
                        })}
                    </ul>
                )
            }
        },
        {
            key: "name",
            parent: "status",
            label: "Status",
            order: true,
        },
    ];
    const actions = [
        {
            icon: faEye,
            title: 'Details',
            click: (id) => {
                navigate({ pathname: "/rentals/" + id });
            },
        }
    ]
    if (error) {
        return <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true}>
            <Alert variant="danger">
                <span>
                    {error.message} {error.detail}
                </span>
            </Alert>
        </FlashMessage>
    } else if (!isLoaded) {
        return <div className="text-center"><Spinner /></div>;
    } else {
        return <ObjectListing objectname="rentals" action={"me"} actions={actions} showTitle={true} admin={false} title="Meine Vorgänge" icon={faRetweet} attributes={attributes} defaultSort="created" defaultAsc="false" add={false} useuuid={false} edit={false} delete={false} filters={filters} search={true} headerButtons={[]} />
    }
}