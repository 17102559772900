import { Version } from './Version';

export function Footer() {

    return (
        <footer className="mt-auto container align-self-end pt-4">
            <div className="text-end mb-1">
                <small>
                    <Version />
                </small>
            </div>
        </footer>
    )
}