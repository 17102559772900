import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserCog, faShoppingBasket, faCheck } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../../components/ObjectListing'
import { Time } from '../../components/Time'

export function Users() {
    var filters = []
    const rowClass = (i) => {
        var ret = []
        if (i.last_login) {
            const d = Date.parse(i.last_login);
            if (Date.now() - d < 500000) {
                ret.push('online');
            }
        }
        if (i.uuid === process.env.REACT_APP_COOLDOWN_USER || i.uuid === process.env.REACT_APP_REPAIR_USER) {
            ret.push('systemuser');
        }
        return ret.join(' ');
    }
    const attributes = [
        {
            key: 'firstname',
            label: "Firstname",
            order: true,
        },
        {
            key: "lastname",
            label: "Lastname",
            order: true,
        },
        {
            key: "einrichtung",
            label: "Einrichtung",
            order: true,
        },
        {
            key: 'email',
            parent: "user",
            label: "Email",
            order: true,
        },
        {
            key: 'email_confirmed',
            label: "Email bestätigt",
            order: true,
            format: (t) => {
                return t ? <FontAwesomeIcon icon={faCheck} /> : ''
            }
        },
        {
            key: 'last_login',
            label: 'Letzter Login',
            order: true,
            format: (d) => {
                const da = new Date(d)
                return (<div className={(Date.now() - da < 500000) ? 'text-success' : ''}>{Time({
                    date: d,
                    withTime: true
                })}</div>)
            }
        },
        {
            key: 'roles',
            label: 'Rollen',
            format: (groups) => {
                if (Array.isArray(groups) && groups.length > 0) {
                    return <span>
                        {groups.map((g) => {
                            if (g.id === 1) {
                                return <FontAwesomeIcon key={"ki" + g.id} icon={faUserCog} title={g.name} className="text-danger me-2" />
                            }
                            if (g.id === 2) {
                                return <FontAwesomeIcon key={"ki" + g.id} icon={faUser} title={g.name} className="text-warning me-2" />
                            }
                            if (g.id === 3) {
                                return <FontAwesomeIcon key={"ki" + g.id} icon={faShoppingBasket} title={g.name} className="text-grey me-2" />
                            }
                            return ''
                        })}
                    </span>
                }
                return ''
            }
        }
    ];
    return <ObjectListing objectname="users" showTitle={true} admin={true} title="Users" icon={faUser} attributes={attributes} rowClass={rowClass} defaultSort="email" defaultAsc="true" add={true} useuuid={true} edit={true} delete={true} filters={filters} search={true} headerButtons={[]} />
}